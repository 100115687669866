/* eslint-disable no-unused-vars */
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Swiper from 'swiper/bundle'
import mobileAnimation from './js/menu'
// configure Swiper to use modules

mobileAnimation()

gsap.registerPlugin(ScrollTrigger)

var imagesLoaded = require('imagesloaded')
var $ = require('jquery')
imagesLoaded.makeJQueryPlugin($)
// now use .imagesLoaded() jQuery plugin
var jQueryBridget = require('jquery-bridget')
var Isotope = require('isotope-layout')
// make Isotope a jQuery plugin
jQueryBridget('isotope', Isotope, $)
// now you can use $().isotope()
// add cellsByRow layout mode
require('isotope-cells-by-row')

// particlesJS.load('particles-js', '/assets/particles.json', function () {
//   console.log('callback - particles.js config loaded')
// })

var overlayerModul = document.getElementById('Overlayer')
var body = document.body
let logo = document.getElementById('logo')
let tl = gsap.timeline()
let AnimationItems = document.querySelectorAll('.animated')
let AnimationItems2 = document.querySelectorAll('.animated2')
console.log('AnimationItems', AnimationItems)

if (document.getElementsByClassName('swiper-container').length > 0) {
  var mySwiper = new Swiper('.swiper-container', {
    // Optional parameters
    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    lazy: true,
    keyboard: {
      enabled: true,
      onlyInViewport: false
    },
    direction: 'horizontal',
    loop: true,

    // If we need pagination
    pagination: {
      el: '.swiper-pagination'
    },

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },

    // And if we need scrollbar
    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true
    }
  })
}

$('.album-gallery li').click(function () {
  //   overlayerModul.style.display = 'block'
  overlayerModul.classList.toggle('active')
  //   body.style.overflow = 'hidden'
  let slideNumber = $(this).attr('data-index')
  console.log('Slidenumber', slideNumber)
  mySwiper.slideTo(slideNumber, 1.25)
})

$('.Overlayer__close').click(function () {
  overlayerModul.classList.toggle('active')
  //   body.style.overflow = 'auto'
})

if (AnimationItems.length > 0) {
  $('.animated')
    .imagesLoaded()
    .done(function (instance) {
      console.log('DONE  - all images have been successfully loaded')
      tl.from(AnimationItems, {
        transform: 'scale(0.001)',
        opacity: 0,
        stagger: 0.15,
        ease: 'power2.inOut',
        duration: 1
        //   scrollTrigger: '.animated'
      })

      document.getElementsByClassName(
        'animated__container'
      )[0].style.opacity = 1
    })
}

if (AnimationItems2.length > 0) {
  $('.animated2')
    .imagesLoaded()
    .done(function (instance) {
      console.log(
        'DONE  - all images (animation2) have been successfully loaded'
      )
      tl.from(AnimationItems2, {
        y: 100,
        opacity: 0,
        stagger: 0.15,
        ease: 'power2.inOut',
        duration: 1
        //   scrollTrigger: '.animated'
      })

      document.getElementsByClassName(
        'animated__container'
      )[0].style.opacity = 1
    })
}

var $grid = $('.grid').imagesLoaded(function () {
  // init Isotope after all images have loaded
  $grid.isotope({
    stagger: 10
    // options...
  })
  // filter items on button click
  $('.filters-button-group').on('click', 'a', function () {
    $('.filters-button-group').find('.is-checked').removeClass('is-checked')
    $(this).addClass('is-checked')
    var filterValue = $(this).attr('data-filter')
    $grid.isotope({ filter: filterValue })
  })
})

/* ==================================================================
    [ Focus Contact2 ] */
$('.input').each(function () {
  $(this).on('blur', function () {
    if ($(this).val().trim() != '') {
      $(this).addClass('has-val')
    } else {
      $(this).removeClass('has-val')
    }
  })
})
