export default function mobileAnimation () {
  console.log('Menuing')
  let mobileMenuButton = document.getElementById('mobileMenu')
  let mobileMenu = document.getElementById('menu')


  mobileMenuButton.onclick = function () {
    mobileMenu.classList.toggle('active')
    mobileMenuButton.classList.toggle('active')
  }
}
